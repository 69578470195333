import { CallToAction } from "./Landing/CallToAction";
import { Faqs } from "./Landing/Faqs";
import { Footer } from "./Landing/Footer";
import { Header } from "./Landing/Header";
import { Hero } from "./Landing/Hero";
import { Pricing } from "./Landing/Pricing";
import { PrimaryFeatures } from "./Landing/PrimaryFeatures";
import { SecondaryFeatures } from "./Landing/SecondaryFeatures";
import { Testimonials } from "./Landing/Testimonials";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { BannerWithLinks } from "../UI/Banner";
import SEO from "./SEO";
export default function Home() {
  return (
    <>
      <SEO />
      <div id="banner"></div>
      <BannerWithLinks 
        link="/register" 
        strongText="✨ New! (Beta)"
      >
        🌎 Now supporting 30+ languages including 🇨🇳 Chinese, 🇫🇷 French, 🇩🇪 German, 🇪🇸 Spanish and more. Sign up now →
      </BannerWithLinks>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <CallToAction />
        <Pricing />
        <Faqs />
      </main>
      <Footer />
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, PlusIcon } from "@heroicons/react/outline";
import useUIState from "../../hooks/useUIState";
import useAuth from "../../hooks/useAuth";
import AddInboxContentModal from "./add_inbox/AddInboxContentModal";
import toast, { Toaster } from "react-hot-toast";
import ActionModalPleaseSubscribeToUse from "../../UI/ActionModalPleaseSubscribeToUse";

export default function InboxSidebar(props) {
  const { UIState, setUIState } = useUIState();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const [isAddContentModalOpen, setIsAddContentModalOpen] = useState(false);

  const handleOpenAddContentModal = () => {
    setIsAddContentModalOpen(true);
    setOpen(false); // Close the inbox
  };

  useEffect(() => {
    setOpen(UIState.inboxSidebarOpen);
  }, [UIState]);

  useEffect(() => {
    if (open) {
      setUIState({
        ...UIState,
        inboxSidebarOpen: open,
      });
    } else {
      setUIState({
        ...UIState,
        inboxSidebarOpen: false,
      });
    }
  }, [open]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full">
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                    <div className="flex h-screen flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Inbox</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center space-x-4">
                            <button
                              onClick={handleOpenAddContentModal}
                              className="inline-flex items-center justify-center p-1.5 border border-transparent rounded-md shadow-sm text-white bg-secondary-500 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-400"
                              title="Add Content">
                              <PlusIcon className="h-4 w-4" aria-hidden="true" />
                              <span className="sr-only">Add Content</span>
                            </button>
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}>
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1">
                        {props.isEmpty ? (
                          <div className="py-4 px-4 text-center">
                            <p className="text-gray-500 mb-4">Your inbox is empty.</p>
                            <button
                              onClick={handleOpenAddContentModal}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                            >
                              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                              Add Content
                            </button>
                          </div>
                        ) : (
                          props.children
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <AddInboxContentModal show={isAddContentModalOpen} onClose={() => setIsAddContentModalOpen(false)} />
    </>
  );
}

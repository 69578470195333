import React, { useState } from "react";
import PodcastSearch from "./PodcastSearch";
import PodcastEpisodes from "./PodcastEpisodes";
import { axiosPrivate } from "../../../common/axiosPrivate";

export default function SearchPodcastTab({ onClose, isLimitReached }) {
  const [selectedPodcast, setSelectedPodcast] = useState(null);

  const handleFavoriteOnClick = async (feedId, isFavorite) => {
    try {
      await axiosPrivate.post(`/podcast/favorites/${feedId}`, {
        is_favorite: isFavorite,
      });
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
  };

  const handleSelectPodcast = (podcast) => {
    setSelectedPodcast(podcast);
  };

  const handleBack = () => {
    setSelectedPodcast(null);
  };

  return selectedPodcast ? (
    <PodcastEpisodes
      podcast={selectedPodcast}
      onBack={handleBack}
      handleFavoriteOnClick={handleFavoriteOnClick}
      onClose={onClose}
    />
  ) : (
    <PodcastSearch
      onSelectPodcast={handleSelectPodcast}
      handleFavoriteOnClick={handleFavoriteOnClick}
      isLimitReached={isLimitReached}
    />
  );
}

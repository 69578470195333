import React, { useEffect, useState, useReducer, useContext } from "react";
import { PlusIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import Button from "../../../UI/Button";
import PodcastCard from "../../../UI/PodcastCard";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { BarLoader } from "react-spinners";
import { useDebounceValue } from "usehooks-ts";
import SearchInput from "../../../UI/SearchComponent";
import PodcastProfileHeadingCard from "../../../UI/PodcastProfileHeadingCard";
import PodcastEpisodeCard from "../../../UI/PodcastEpisodeCard";
import LibraryDataContext from "../../../context/LibraryDataProvider";

const FETCH_DATA = "FETCH_DATA";
const FILTER_FEEDS = "FILTER_FEEDS";
const SHOW_MORE = "SHOW_MORE";
const FETCH_EPISODES = "FETCH_EPISODES";

const initialState = {
  feeds: [],
  filteredFeeds: [],
  displayCount: 5,
  selectedPodcast: null,
  episodes: [],
  currentPage: 1,
  totalPages: 1,
  itemsPerPage: 10,
  totalItems: 0,
};

const feedsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        feeds: action.payload,
      };
    case FILTER_FEEDS:
      if (action.payload) {
        return {
          ...state,
          filteredFeeds: state.feeds
            .filter((feed) => feed.title.toLowerCase().includes(action.payload.toLowerCase()))
            .sort((a, b) => b.is_favorite - a.is_favorite),
        };
      }
      return {
        ...state,
        filteredFeeds: [...state.feeds].sort((a, b) => b.is_favorite - a.is_favorite).slice(0, state.displayCount),
      };
    case "FAVORITE":
      const feedId = action.payload.feedId;
      const isFavorite = action.payload.isFavorite;
      const feeds = state.feeds.map((feed) => {
        if (feed.id === feedId) {
          return { ...feed, is_favorite: isFavorite };
        }
        return feed;
      });
      return {
        ...state,
        feeds,
      };
    case SHOW_MORE:
      return {
        ...state,
        displayCount: state.displayCount + 5,
      };
    case "SELECT_PODCAST":
      return {
        ...state,
        selectedPodcast: action.payload,
        currentPage: 1,
      };
    case FETCH_EPISODES:
      return {
        ...state,
        episodes: action.payload.episodes,
        currentPage: action.payload.page,
        totalPages: action.payload.pages,
        itemsPerPage: action.payload.size,
        totalItems: action.payload.total,
      };
    case "UPDATE_EPISODE":
      return {
        ...state,
        episodes: state.episodes.map((episode) =>
          episode.id === action.payload.episodeId
            ? { ...episode, is_added_to_library: action.payload.isAdded }
            : episode
        ),
      };
    case "CHANGE_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

export default function FavoritesTab({ onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(feedsReducer, initialState);
  const [filterTerm, setFilterTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const { addLibraryEpisode, removeLibraryEpisode } = useContext(LibraryDataContext);

  const handlePodcastClick = async (podcast) => {
    dispatch({ type: "SELECT_PODCAST", payload: podcast });
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/podcast/${podcast.id}/episodes?page=1&size=${state.itemsPerPage}`);
      dispatch({ type: FETCH_EPISODES, payload: {
        episodes: response.data.items,
        page: response.data.page,
        pages: response.data.pages,
        size: response.data.size,
        total: response.data.total
      }});
    } catch (error) {
      console.error("Error fetching episodes: ", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= state.totalPages) {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(`/podcast/${state.selectedPodcast.id}/episodes?page=${newPage}&size=${state.itemsPerPage}`);
        dispatch({ type: FETCH_EPISODES, payload: {
          episodes: response.data.items,
          page: response.data.page,
          pages: response.data.pages,
          size: response.data.size,
          total: response.data.total
        }});
      } catch (error) {
        console.error("Error fetching episodes: ", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(`/podcast/favorites`);
        dispatch({ type: FETCH_DATA, payload: response.data.podcasts });
        dispatch({ type: FILTER_FEEDS });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching favorites: ", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFavoriteOnClick = async (feedId, isFavorite) => {
    try {
      await axiosPrivate.post(`/podcast/favorites/${feedId}`, {
        is_favorite: isFavorite,
      });
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
    setFilterTerm("");
    dispatch({ type: "FAVORITE", payload: { feedId, isFavorite } });
    dispatch({ type: FILTER_FEEDS });
  };

  const handleShowMore = () => {
    dispatch({ type: SHOW_MORE });
    dispatch({ type: FILTER_FEEDS });
  };

  const handleSearch = (e) => {
    setDebouncedSearchValue(e.target.value);
    setFilterTerm(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: FILTER_FEEDS, payload: debouncedSearchValue });
  }, [debouncedSearchValue]);

  const handleBack = () => {
    dispatch({ type: "SELECT_PODCAST", payload: null });
  };

  const handleAddRemove = async (feedId, episodeId, isAdded) => {
    if (isAdded) {
      await addLibraryEpisode(feedId, episodeId);
    } else {
      await removeLibraryEpisode(feedId, episodeId);
    }
    // Update the local state to reflect the change
    dispatch({ type: "UPDATE_EPISODE", payload: { episodeId, isAdded } });
  };

  if (state.selectedPodcast) {
    return (
      <div className="h-full overflow-y-auto">
        <Button onClick={handleBack} className="mb-4">
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Back to Favorites
        </Button>
        <PodcastProfileHeadingCard feed={state.selectedPodcast} handleFavorite={handleFavoriteOnClick} />
        <h2 className="text-xl font-bold text-left text-primary-600 mt-4">Episodes</h2>
        {state.isLoading && state.episodes.length === 0 ? (
          <BarLoader width={"100%"} color={"#fca311"} loading={state.isLoading} size={150} />
        ) : (
          <>
            {state.episodes.map((episode, index) => (
              <PodcastEpisodeCard
                key={index}
                episode={episode}
                handleAddRemove={handleAddRemove}
                onCloseModal={onClose}
              />
            ))}
            <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-6">
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(state.currentPage - 1) * state.itemsPerPage + 1}</span> to{" "}
                  <span className="font-medium">{Math.min(state.currentPage * state.itemsPerPage, state.totalItems)}</span>{" "}
                  of <span className="font-medium">{state.totalItems}</span> results
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                <button
                  onClick={() => handlePageChange(state.currentPage - 1)}
                  disabled={state.isLoading || state.currentPage === 1}
                  className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Previous
                </button>
                <button
                  onClick={() => handlePageChange(state.currentPage + 1)}
                  disabled={state.isLoading || state.currentPage === state.totalPages}
                  className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                </button>
              </div>
            </nav>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="h-full overflow-y-auto px-4 sm:px-6 lg:px-8">
      <BarLoader width={"100%"} color={"#fca311"} loading={isLoading} size={150} />
      <div className="max-w-3xl mx-auto">
        <SearchInput
          value={filterTerm}
          onChange={handleSearch}
          className="mb-6 w-full"
          placeholder="Search favorite podcasts..."
        />
        {!isLoading && state.feeds.length === 0 && (
          <div className="text-center mt-8">
            <h3 className="text-lg text-neutral-400">
              You have no favorite podcasts yet. Use the search tab to find podcasts to favorite.
            </h3>
          </div>
        )}
        <div className="grid gap-6">
          {!isLoading &&
            state.filteredFeeds.map((feed, index) => (
              <PodcastCard
                key={index}
                feed={feed}
                handleFavorite={handleFavoriteOnClick}
                onClick={handlePodcastClick}
              />
            ))}
        </div>
        {!filterTerm && !isLoading && state.displayCount < state.feeds.length && (
          <div className="mt-8 text-center">
            <Button type="button" onClick={handleShowMore} className="py-2 px-4 gap-x-2">
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, SearchIcon, StarIcon, DocumentTextIcon, UploadIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import FavoritesTab from "./FavoritesTab";
import SearchPodcastTab from "./SearchPodcastTab";
import ProcessedTab from "./ProcessedTab";
import CustomUploadsTab from "./CustomUploadsTab";
import useLibraryData from "../../../hooks/useLibraryData";
import useAuth from "../../../hooks/useAuth";
import { Link } from "react-router-dom";

const EmptyTab = () => (
  <div className="text-center py-8">
    <p>You've reached the inbox limit. Please upgrade your subscription to add more items.</p>
  </div>
);

const tabs = [
  { name: "Search Podcast", component: SearchPodcastTab, icon: SearchIcon },
  { name: "Favorites", component: FavoritesTab, icon: StarIcon },
  { name: "Processed", component: ProcessedTab, icon: DocumentTextIcon },
  { name: "Custom Uploads", component: CustomUploadsTab, icon: UploadIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AddInboxContentModal({ show, onClose }) {
  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const { LibraryData } = useLibraryData();
  const { auth } = useAuth();

  const ActiveTabComponent = isLimitReached ? EmptyTab : (tabs.find((tab) => tab.name === activeTab)?.component || (() => null));

  const [inboxLimit, setInboxLimit] = useState(10);
  const [inboxUsage, setInboxUsage] = useState(0);

  useEffect(() => {
    const checkInboxLimit = () => {
      const limit = auth.user.subscription_status === "active" ? Infinity : 10;
      setInboxLimit(limit);
      setInboxUsage(LibraryData.episodes.length);
      const limitReached = LibraryData.episodes.length >= limit;
      setIsLimitReached(limitReached);
      if (limitReached) {
        setActiveTab("Empty");
      }
    };
    console.log("LibraryData.episodes.length:", LibraryData.episodes.length);

    checkInboxLimit();
  }, [LibraryData, auth.user.subscription_status]);

  const getProgressPercentage = () => {
    if (inboxLimit === Infinity) return 0;
    return Math.min((inboxUsage / inboxLimit) * 100, 100);
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 max-w-full">
                <div className="absolute right-0 top-0 pr-4 pt-4 z-10">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add to Inbox
                    </Dialog.Title>
                    <div className="mt-4 mb-4">
                      <div className="flex items-center justify-between mb-2">
                        <h3 className="text-sm font-medium text-gray-900">Inbox Usage</h3>
                        <span className="text-sm font-medium text-gray-900">
                          {inboxUsage} / {inboxLimit === Infinity ? '∞' : inboxLimit}
                        </span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                          className={`h-2.5 rounded-full ${
                            isLimitReached ? 'bg-red-600' : 'bg-blue-600'
                          }`}
                          style={{ width: `${getProgressPercentage()}%` }}
                        ></div>
                      </div>
                      <div className="mt-2 flex items-center">
                        {isLimitReached ? (
                          <>
                            <ExclamationIcon className="h-5 w-5 text-red-500 mr-2" />
                            <p className="text-sm text-red-700">
                              Inbox limit reached. Please{" "}
                              <Link
                                to="/subscription"
                                className="font-medium underline hover:text-red-800 transition ease-in-out duration-150"
                              >
                                upgrade your subscription
                              </Link>{" "}
                              to add more items.
                            </p>
                          </>
                        ) : (
                          <>
                            <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                            <p className="text-sm text-green-700">
                              You can add more items to your inbox.
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="tabs"
                          name="tabs"
                          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          value={activeTab}
                          onChange={(e) => setActiveTab(e.target.value)}
                          disabled={isLimitReached}>
                          {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden sm:block">
                        <nav className="flex space-x-4" aria-label="Tabs">
                          {tabs.map((tab) => (
                            <button
                              key={tab.name}
                              onClick={() => setActiveTab(tab.name)}
                              disabled={isLimitReached}
                              className={classNames(
                                tab.name === activeTab
                                  ? "bg-indigo-100 text-indigo-700"
                                  : "text-gray-500 hover:text-gray-700",
                                "rounded-md px-3 py-2 text-sm font-medium flex items-center",
                                isLimitReached && "opacity-50 cursor-not-allowed"
                              )}>
                              <tab.icon className="h-5 w-5 mr-2" aria-hidden="true" />
                              {tab.name}
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <div className="mt-4">
                      <ActiveTabComponent onClose={onClose} isLimitReached={isLimitReached} />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
